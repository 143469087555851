<template>
    <div class="admin_page_body" v-loading="isRequestLoading">
        <el-form ref="form" :inline="true" :model="form" size="small" label-position="top">
            <div class="admin_page_item mt18 pr0 pb0">
                <h1 class="title">基本信息</h1>
                <div class="data_detail mt24">
                    <el-form-item label="标题">
                        <span class="text">{{infoData.title}}</span>
                    </el-form-item>

                    <el-form-item label="供应单号">
                        <span class="text">{{infoData.code}}</span>
                    </el-form-item>

                    <el-form-item label="发布时间">
                        <span class="text">{{infoData.createdAt}}</span>
                    </el-form-item>

                    <el-form-item label="预出售时间">
                        <span class="text">{{infoData.receiptAt}}</span>
                    </el-form-item>

                    <el-form-item label="店铺名称">
                        <span class="text">{{infoData.storeName}}</span>
                    </el-form-item>
                </div>
            </div>

            <div class="admin_page_item mt18 pr0 pb0">
                <h1 class="title">供应商品</h1>
                <div class="data_detail mt24">
                    <el-form-item label="商品名称">
                        <span class="text">{{infoData.goodName}}</span>
                    </el-form-item>

                    <el-form-item label="规格">
                        <span class="text">{{infoData.sku}}</span>
                    </el-form-item>

                    <el-form-item label="供应数量">
                        <span class="text">{{infoData.num}}{{infoData.unitName}}</span>
                    </el-form-item>

                    <el-form-item label="供应价格">
                        <span class="text">￥ {{infoData.price}} （{{getPriceStatus(infoData.priceStatus)}}）</span>
                    </el-form-item>

                    <el-form-item label="商品图片" class="full">
                        <el-image fit="contain" v-if="infoData.imageUrl"
                                  style="width: 100px; height: 100px"
                                  :src="imageURL + infoData.imageUrl"
                                  :preview-src-list="[imageURL + infoData.imageUrl]">
                        </el-image>
                    </el-form-item>

                    <el-form-item label="联系人">
                        <span class="text">{{infoData.contactName}}</span>
                    </el-form-item>

                    <el-form-item label="联系方式">
                        <span class="text">{{infoData.contactPhone}}</span>
                    </el-form-item>

                    <el-form-item label="详细说明" class="full">
                        <span class="text">{{infoData.content}}</span>
                    </el-form-item>

                    <el-form-item label="驳回原因" class="full" v-if="infoData.auditStatus === 4">
                        <span class="text">{{infoData.reason}}</span>
                    </el-form-item>
                </div>
            </div>

            <div class="page_footer mt24">
                <el-button type="primary" size="small" @click="$router.go(-1)">返回，供应管理</el-button>
            </div>
        </el-form>
    </div>
</template>

<script>
  import {imageURL} from "../../../../utils/config";
  import {getSupplyInfo} from "../../../../api/admin/supplyBuying";

  export default {
    name: "supplyBuyingBuyingDetail",
    data () {
      return {
        isRequestLoading: false,
        form: {},
        imageURL: imageURL,
        infoData: {},
        quotedPrices: []
      }
    },
    created() {
      this.$store.commit('changeScrollTop');
      this.getSupplyInfo()
    },
    methods: {
      getSupplyInfo () {
        this.isRequestLoading = true
        getSupplyInfo(this.$route.query.id).then(res => {
          this.isRequestLoading = false
          if (res.code === 0) {
            this.infoData = res.data
          }
        }).catch(() => {
          this.isRequestLoading = false
        })
      },
      getPriceStatus (status) {
        switch (status) {
          case 1:
            return '可议价';
          case 2:
            return '不可议价';
          case 3:
            return '面议';
        }
      }
    },
    components: {}
  }
</script>
